<template>
  <div class="workspace">
    <div class="workspace-header">
      <div class="search-block">
        <base-input
          class="search-input mr-3 mb-0"
          inputClasses="form-control-lg"
          placeholder="Search..."
          v-model="searchKeywords"
          type="search"
        >
          <!-- <div slot="addonRight">
            <base-button icon="fas fa-search" class="btn-black"> </base-button>
          </div> -->
        </base-input>
      </div>
      <div class="header-fill"></div>
      <div class="sort-block">
        <base-dropdown class="m-1" position="right">
          <a
            slot="title"
            class="btn btn-light text-truncate"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <template v-if="orderBy">
              <i
                :class="{
                  'fa-sort-amount-up':
                    orderBy.direction === SortDirection.Ascending,
                  'fa-sort-amount-down':
                    orderBy.direction === SortDirection.Descending
                }"
                class="fas"
              ></i>
              {{ orderBy.sortItem.label }}
            </template>
            <template v-else>
              <i class="fas fa-filter"></i> Sort
              <i class="fas fa-angle-down"></i>
            </template>
          </a>
          <template v-for="(orderByItem, index) in orderByList">
            <a
              :key="orderByItem.value"
              @click="setOrderBy(orderByItem)"
              class="dropdown-item px-3 py-1"
            >
              <i
                class="fas"
                :class="{
                  'fa-sort-amount-up':
                    orderBy && orderBy.direction === SortDirection.Ascending,
                  'fa-sort-amount-down':
                    orderBy && orderBy.direction === SortDirection.Descending
                }"
                v-show="orderBy && orderBy.sortItem.value === orderByItem.value"
              ></i>
              {{ orderByItem.label }}
              <i
                class="fas fa-times float-right mt-1 mr-0 ml-2"
                v-show="orderBy && orderBy.value === orderByItem.value"
                @click.stop="resetOrderBy()"
              ></i>
            </a>
            <div
              v-if="orderByList.length > index + 1"
              class="dropdown-divider"
              :key="index"
            ></div>
          </template>
        </base-dropdown>
      </div>
      <div class="filter-block">
        <base-dropdown class="m-1" position="right">
          <a
            slot="title"
            class="btn btn-light"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              class="dropdown-icon"
              src="/app/img/icons/common/filter-list-black-18dp.svg"
            />
            Filter ({{ filterBy.label }})
            <i class="fas fa-angle-down"></i>
          </a>
          <template v-for="(filterByItem, index) in filterByList">
            <a
              :key="filterByItem.value"
              @click="setFilterBy(filterByItem)"
              class="dropdown-item px-3 py-1"
            >
              {{ filterByItem.label }}
              <i
                v-show="filterBy && filterBy.value === filterByItem.value"
                class="fas fa-check float-right mt-1 mr-0"
              ></i>
            </a>
            <div
              v-if="filterByList.length > index + 1"
              class="dropdown-divider"
              :key="index"
            ></div>
          </template>
        </base-dropdown>
      </div>
      <div class="contact-leads-block">
        <base-button class="btn-black m-1" @click="showBulkSending()">
          <span class="material-icons-outlined">email</span> Contact
          influencers
        </base-button>
      </div>
    </div>
    <div class="workspace-content">
      <div class="influencers-list">
        <div class="influencers-list-header">
          <div class="column-influencer">Influencer</div>
          <div class="column-campaign">Campaign</div>
          <div class="column-status">Status</div>
          <div class="column-coupons">Coupons</div>
          <!-- <div class="column-agreement-link">Agreement Link</div> -->
          <div class="column-tracking-link">Tracking Link</div>
          <div class="column-pay" v-if="enablePayments">Pay</div>
        </div>

        <div class="influencers-list-content" v-if="!isInfluencersLoading">
          <div
            v-for="(lead, index) in influencers"
            :key="lead.id"
            class="influencer-list-item"
          >
            <!-- Avatar, name, description cell -->
            <div
              class="column-influencer"
              :class="{ 'has-message': lead.hasLastMessage }"
            >
              <div class="influencer-info">
                <img
                  class="avatar avatar-100"
                  :src="lead.profile_pic"
                  @error="onLogoImageError($event)"
                  alt
                />
                <div class="influencer-text-info">
                  <div class="username" :title="lead.username">
                    <div class="lead-fullname text-truncate">
                      {{ lead.fullName }}
                    </div>
                    <div
                      v-if="lead.lastMessageDate"
                      class="lead-last-message-time"
                    >
                      {{ dateIsoToShortTime(lead.lastMessageDate) }}
                    </div>
                    <div class="social-icon">
                      <img
                        v-if="lead.social == 'instagram'"
                        src="/app/img/icons/svg/instagram.svg"
                        alt="Instagram Icon"
                      />
                      <img
                        v-else-if="lead.social == 'tiktok'"
                        src="/app/img/icons/svg/tiktok.svg"
                        alt="Tiktok Icon"
                      />
                      <img
                        v-else-if="lead.social == 'youtube'"
                        src="/app/img/icons/svg/youtube.svg"
                        alt="Youtube Icon"
                      />
                    </div>
                  </div>
                  <div class="last-message-text" @click="goToMessage(lead)">
                    {{ lead.lastMessageText }}
                  </div>
                </div>
              </div>
            </div>
            <div class="column-campaign">{{ lead.campaign }}</div>
            <div class="column-status">
              <base-dropdown class="m-1" position="right">
                <button
                  slot="title"
                  class="btn btn-black text-nowrap"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>
                    {{ lead.stage }} <i class="fas fa-angle-down"></i>
                  </span>
                </button>
                <template v-for="(stage, stageIndex) in lead.stages">
                  <a
                    :key="stageIndex + index"
                    @click="setInfluencerStatus(lead, stage)"
                    class="dropdown-item px-3 py-1"
                  >
                    {{ stage.label }}
                    <i
                      v-show="lead.stage === stage.label"
                      class="fas fa-check float-right mt-1 mr-0 ml-2"
                    ></i>
                  </a>
                  <div
                    :key="'divider-' + stageIndex + index"
                    v-if="lead.stages.length > index + 1"
                    class="dropdown-divider"
                  ></div>
                </template>
              </base-dropdown>
            </div>
            <!-- Coupons cell -->
            <div class="column-coupons">
              <div class="add-coupon">
                <base-button
                  v-if="lead.coupons.length !== 0"
                  icon="fas fa-sticky-note"
                  class="btn-black"
                  :isBusy="lead.loadingCoupons"
                  @click="showManageCoupons(lead)"
                ></base-button>
                <base-button
                  v-if="lead.coupons.length === 0"
                  icon="fas fa-plus"
                  class="btn-black"
                  :isBusy="lead.loadingCoupons"
                  @click="showManageCoupons(lead)"
                ></base-button>
                <div v-if="lead.coupons.length !== 0" class="coupons-counter">
                  {{ lead.coupons.length }}
                </div>
              </div>
            </div>
            <!-- <div class="column-agreement-link">
              <template v-if="!lead.agreementLink">
                <base-button
                  v-if="!lead.addingAgreementLink"
                  icon="fas fa-plus"
                  class="btn-black"
                  @click="addAgreementLink(lead)"
                ></base-button>
                <base-input
                  v-else
                  class="input-group-alternative mr-3 mb-0"
                  placeholder="Add agreement link..."
                  :value="lead.editAgreementLink"
                  v-on:keyup="handleAgreementLinkChanges($event, lead)"
                />
              </template>

              <base-button
                v-if="lead.agreementLink"
                icon="fas"
                class="btn-black btn-check-delete-hover"
                @click="removeAgreementLink(lead)"
              ></base-button>
              <div
                v-if="lead.agreementLink"
                class="link-value text-truncate"
                :title="lead.agreementLink"
              >
                {{ lead.agreementLink }}
              </div>
            </div> -->
            <div class="column-tracking-link">
              <div class="add-tracking-link">
                <base-button
                  v-if="lead.trackingLinks.length !== 0"
                  icon="fas fa-link"
                  class="btn-black"
                  :isBusy="lead.loadingTrackingLinks"
                  @click="showManageTrackingLinks(lead)"
                ></base-button>
                <base-button
                  v-if="lead.trackingLinks.length === 0"
                  icon="fas fa-plus"
                  class="btn-black"
                  :isBusy="lead.loadingTrackingLinks"
                  @click="showManageTrackingLinks(lead)"
                ></base-button>
                <div
                  v-if="lead.trackingLinks.length !== 0"
                  class="tracking-links-counter"
                >
                  {{ lead.trackingLinks.length }}
                </div>
              </div>
            </div>
            <div class="column-pay" v-if="enablePayments">
              <base-button class="btn-black" @click="showPayment(lead)"
                >Pay</base-button
              >
            </div>
          </div>
          <div class="leads-list-empty" v-if="influencers.length === 0">
            <h2>
              Here you will see all you leads that you added to campaigns. Use
              our
              <router-link class to="/app/influencers">search</router-link> to
              find your influencers!
            </h2>
          </div>
        </div>
        <div class="influencers-loading" v-else>
          <base-spinner></base-spinner>
          <div class="pl-2 mt-2">loading...</div>
        </div>
      </div>
    </div>

    <edit-lead-coupon-panel
      :show="showEditLeadCoupon"
      @close="onCloseManageCoupons"
      @conflict="hasCouponConflictAtAdding"
      :lead="selectedLead"
      :campaign="campaign"
      :animationDuration="500"
      ref="editLeadCoupon"
    />
    <edit-lead-tracking-panel
      :show="showEditLeadTracking"
      @close="onCloseManageTrackingLinks"
      :lead="selectedLead"
      :campaign="campaign"
      :animationDuration="500"
    />
    <lead-panel
      :profileRequestData="leadPanelRequestData"
      :campaignId="leadPanelCampaignId"
      :show="showLeadPanel"
      @close="closeLeadPanel"
    >
    </lead-panel>
    <side-panel 
      :show="showBulkSendingPanel"
      @close="onCloseBulkSending" >
      <template v-slot:topbar-actions></template>
      <bulk-sending></bulk-sending>
    </side-panel>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

const EditLeadCouponPanel = () => import("../components/EditLeadCouponPanel");
const EditLeadTrackingPanel = () =>
  import("../components/EditLeadTrackingPanel");

const LeadPanel = () => import("../components/LeadPanel");
const BulkSending = () => import("../components/BulkSending");

const SidePanel = () => import("../components/SidePanel/SidePanel.vue");


const ENABLE_PAYMENTS = process.env.VUE_APP_ENABLE_PAYMENTS === "true";

import {
  WP_ORDER_BY_ITEMS,
  WP_FILTER_BY_ITEMS,
  SortDirection
} from "../store/modules/workspace";

import { MESSAGES } from "@/constants";
import { dateIsoToShortTime, onLogoImageError } from "../utils/index";

import { ProfilePanelRequestData } from "../models/leadProfile";

export default {
  components: {
    LeadPanel,
    SidePanel,
    BulkSending,
    EditLeadCouponPanel,
    EditLeadTrackingPanel
  },
  data() {
    return {
      filterText: "",

      orderByList: WP_ORDER_BY_ITEMS,
      filterByList: WP_FILTER_BY_ITEMS,
      SortDirection: SortDirection,

      selectedLead: {},
      campaign: {
        currency: ""
      },
      showEditLeadCoupon: false,
      showEditLeadTracking: false,
      showConfirmCouponConflict: false,

      enablePayments: true,
      showLeadPanel: false,
      leadPanelRequestData: null,
      leadPanelCampaignId: null,

      showBulkSendingPanel: false,
    };
  },
  props: {},
  computed: {
    ...mapState({
      searchText: (state) => state.workspace.searchText,
      filterByObject: (state) => state.workspace.filterBy,
      orderByObject: (state) => state.workspace.orderBy,
      showAlert: (state) => state.alert.showAlert,
      isInfluencersLoading: (state) => state.workspace.isInfluencersLoading
    }),
    ...mapGetters({
      influencers: "workspace/influencerResultList",
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess"
    }),

    orderBy: {
      set(value) {
        this.$store.dispatch("workspace/setOrderBy", value);
      },
      get() {
        return this.orderByObject;
      }
    },
    filterBy: {
      set(value) {
        this.$store.dispatch("workspace/setFilterBy", value);
      },
      get() {
        return this.filterByObject;
      }
    },
    searchKeywords: {
      set(value) {
        this.$store.dispatch("workspace/setSearchText", value);
      },
      get() {
        return this.searchText;
      }
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("workspace/getInfluencers");
    });
  },
  methods: {
    dateIsoToShortTime: dateIsoToShortTime,
    onLogoImageError: onLogoImageError,

    setFilterBy(filterByItem) {
      this.filterBy = JSON.parse(JSON.stringify(filterByItem));
    },

    handleAgreementLinkChanges(event, lead) {
      if (event.keyCode === 13) {
        this.$store.dispatch("workspace/setAgreementLink", lead);
      } else if (event.key === "Escape") {
        this.$store.dispatch("workspace/cancelAgreementLinkAdding", lead);
      } else {
        this.$store.dispatch("workspace/updateAgreementLink", {
          influencer: lead,
          value: event.target.value
        });
      }
    },
    goToMessage(lead) {
      // this.$router.push({name: "inbox", params: {
      //   thread: lead.lastMessage.thread_id,
      //   lead_email: lead.data.emails[0],
      //   lead: lead.id
      // }});
      this.$router.push({
        path: `/app/inbox/?thread=${lead.lastMessage.thread_id}&lead_email=${lead.data.emails[0]}&lead=${lead.id}&has_emails=true`
      });
    },
    setInfluencerStatus(lead, stage) {
      this.$store.dispatch("workspace/setInfluencerStage", {
        influencer: lead,
        stage: stage
      });
    },

    async showManageCoupons(lead) {
      await this.$store.dispatch("workspace/updateLoadingCoupons", {
        leadId: lead.id,
        value: true
      });

      try {
        const { data } = await this.$store.dispatch(
          "campaign/getSingleCampaign",
          lead.campaign_id
        );

        this.campaign = data;
        await this.getCampaignLeadsPreview(this.campaign);
        const index = this.campaign.leads.map((e) => e.id).indexOf(lead.id);
        this.selectedLead = this.campaign.leads[index];

        await this.$store.dispatch("campaign/getSingleLead", {
          campaign_id: lead.campaign_id,
          lead_id: lead.id
        });

        this.showEditLeadCoupon = true;
      } finally {
        await this.$store.dispatch("workspace/updateLoadingCoupons", {
          leadId: lead.id,
          value: false
        });
      }
    },
    async onCloseManageCoupons() {
      const lead = this.selectedLead;
      await this.$store.dispatch("workspace/updateLead", {
        leadId: lead.id,
        campaignId: lead.campaign_id
      });

      this.showEditLeadCoupon = false;
    },

    async getCampaignLeadsPreview(campaign) {
      //this.loading.leads = true;
      campaign.leads = [];
      try {
        const data = await this.$store.dispatch(
          "campaign/getCampaignLeadsPreview",
          {
            campaign_id: campaign.id
          }
        );
        if (data) {
          campaign.leads = data;
        }
        //this.loading.leads = false;
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__get_leads_preview-failed"],
            alertClass: "danger"
          });
        }
        //this.loading.leads = false;
      }
    },

    async showManageTrackingLinks(lead) {
      await this.$store.dispatch("workspace/updateLoadingTrackingLinks", {
        leadId: lead.id,
        value: true
      });

      try {
        const { data } = await this.$store.dispatch(
          "campaign/getSingleCampaign",
          lead.campaign_id
        );

        this.campaign = data;
        await this.getCampaignLeadsPreview(this.campaign);
        const index = this.campaign.leads.map((e) => e.id).indexOf(lead.id);
        this.selectedLead = this.campaign.leads[index];

        await this.$store.dispatch("campaign/getSingleLead", {
          campaign_id: lead.campaign_id,
          lead_id: lead.id
        });

        this.showEditLeadTracking = true;
      } finally {
        await this.$store.dispatch("workspace/updateLoadingTrackingLinks", {
          leadId: lead.id,
          value: false
        });
      }
    },

    async onCloseManageTrackingLinks() {
      const lead = this.selectedLead;
      await this.$store.dispatch("workspace/getCashierSession", {
        leadId: lead.id,
        campaignId: lead.campaign_id
      });

      this.showEditLeadTracking = false;
    },
    hasCouponConflictAtAdding() {
      this.showConfirmCouponConflict = true;
    },
    showPayment(lead) {
      this.showLeadPanel = false;

      this.leadPanelRequestData = new ProfilePanelRequestData(
        lead.social,
        lead.username,
        lead.id
      );
      this.leadPanelCampaignId = lead.campaign_id;

      this.showLeadPanel = true;
    },
    closeLeadPanel() {
      this.showLeadPanel = false;
      this.leadPanelRequestData = null;
      this.leadPanelCampaignId = null;
    },
    showBulkSending() {
      this.showBulkSendingPanel = true;
    },
    onCloseBulkSending() {
      this.showBulkSendingPanel = false;
    },
    ...mapActions({
      addAgreementLink: "workspace/setAddingAgreementLink",
      removeAgreementLink: "workspace/removeAgreementLink",
      setOrderBy: "workspace/setOrderBy",
      resetOrderBy: "workspace/resetOrderBy"
    })
  },
  watch: {}
};
</script>

<style lang="scss">
@import "../assets/scss/pages/workspace";
</style>
