<template>
  <div class="metric-box">
    <div class="metric-content">
      <div class="metric-title">{{ title }}</div>
      <div class="metric-value">{{ value }}</div>
    </div>
    <div class="metric-icon">
      <div class="icon-box" :style="{ backgroundColor: circleColor }">
        <span class="material-icons-outlined" :style="{ color: iconColor }">{{ icon }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "profile-metric-box",
  props: {
    title: {
      type: String
    },
    value: {
      default: "n/a"
    },
    icon: {
      type: String
    },
    circleColor: {
      type: String,
      default: "#cccccc"
    },
    iconColor: {
      type: String,
      default: "#000"
    },
    
  }
};
</script>


<style scoped lang="scss">
@import "../../assets/scss/custom/functions";
@import "../../assets/scss/custom/variables";

.metric-box {
  width: 100%;
  min-height: 80px;

  background: white;
  border-radius: 10px;
  box-shadow: $form-input-shadow;

  display: flex;


  .metric-content {
    flex: 1 1 auto;

    padding: 10px;

    display: flex;
    flex-direction: column;

    .metric-title {
      flex: 0 0 50%;
    }

    .metric-value {
      flex: 0 0 50%;
      font-weight: bold;
    }
  }

  .metric-icon {
    flex: 0 0 80px;
    padding: 10px;

    .icon-box {
      border: 4px solid white;
      border-radius: 30px;
      box-shadow: $form-input-shadow;

      height: 60px;
      width: 60px;

      text-align: center;
      line-height: 65px;
    }
  }
}
</style>