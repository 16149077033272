<template>
  <div
    class="modal profile-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
    @click.self="closePanel"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <div v-if="currentProfile" class="modal-body p-0">
          <!-- profile-topbar -->
          <div class="profile-topbar">
            <div class="profile-topbar-left">
              <a
                slot="left"
                @click="backToPrevious"
                v-if="panelProfileHistory.length !== 0"
                class="back-btn"
              >
                <i class="fas fa-arrow-left"></i> Back
              </a>
              <a slot="left" @click="closePanel" v-else class="back-btn">
                <i class="fas fa-times"></i>
              </a>
            </div>
            <div class="profile-topbar-right">
              <div class="social-links">
                <a
                  class="btn-visit-profile"
                  :href="getProfileLink()"
                  target="_blank"
                  >Visit Profile</a
                >
              </div>

              <!-- add to campaign button -->
              <div class="btn-action-container">
                <base-dropdown
                  v-if="canAddToCampaign"
                  class="nav-item"
                  position="right"
                  :disabled="!hasFullAccess('campaigns')"
                >
                  <base-button
                    :disabled="!hasFullAccess('campaigns')"
                    :disabledTooltip="
                      getPermissionErrorMessage('campaigns:full')
                    "
                    slot="title"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    type="primary"
                    class="btn-campaign"
                  >
                    <span>Add to Campaign</span>
                  </base-button>
                  <a
                    @click="displayEditCampaignPanel"
                    class="
                      dropdown-item dropdown-item--primary
                      px-3
                      py-1
                      cursor-pointer
                    "
                    >Create New...</a
                  >
                  <template v-if="campaignsList && campaignsList.length">
                    <div class="dropdown-divider"></div>
                    <div class="campaigns-list">
                      <template v-for="campaign in campaignsList">
                        <a
                          :key="campaign.name"
                          class="dropdown-item px-3 py-1 cursor-pointer"
                          @click="addLeadsToExistingCampaign(campaign)"
                          >{{ campaign.name }}</a
                        >
                      </template>
                    </div>
                  </template>
                </base-dropdown>
              </div>
            </div>
          </div>

          <!-- Profile header for instagram/youtube/tiktok -->
          <lead-profile-header
            :social="social"
            :currentProfile="currentProfile"
            :highlightedKeyword="filter.keyword"
          >
          </lead-profile-header>

          <div class="profile-tabs">
            <!-- intagram profile tabs -->
            <template v-if="social == socials.instagram.id">
              <!-- Profile tabs -->
              <div class="profile-header-tab px-4 pb-2 pb-sm-4">
                <div
                  class="profile-header-tab-item"
                  @click="showTab(0)"
                  v-bind:class="{ borderitem: tabProfile === 0 }"
                >
                  Statistic and Metrics
                </div>

                <div
                  class="profile-header-tab-item"
                  @click="showTab(1)"
                  v-bind:class="{ borderitem: tabProfile === 1 }"
                >
                  Influencer Audience
                </div>

                <div
                  class="profile-header-tab-item"
                  @click="showTab(2)"
                  v-bind:class="{ borderitem: tabProfile === 2 }"
                >
                  Posts and Videos
                </div>

                <div
                  v-if="similarProfiles && similarProfiles.length"
                  class="profile-header-tab-item"
                  @click="showTab(3)"
                  v-bind:class="{ borderitem: tabProfile === 3 }"
                >
                  Related Profiles
                </div>
              </div>

              <div class="profile-panel-content">
                <div v-if="tabProfile === 0" class="">
                  <div
                    class="
                      profile-panel-content__block
                      profile-panel-content__block--main-values
                      p-4
                    "
                  >
                    <h2 class="flex-column mt-2 mb-2">General information</h2>
                    <div class="profile-content-cards">
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">followers</div>

                          <div class="value--large">
                            {{ currentProfile.followers | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Followers.svg"
                          class="card-icon"
                        />
                      </div>
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Posts</div>

                          <div class="value--large">
                            {{ currentProfile.posts | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Posts.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Following</div>

                          <div class="value--large">
                            {{ currentProfile.following | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Following.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Post value</div>

                          <div class="value--large">
                            {{
                              currentProfile.cost_post
                                | NormalNumber(0)
                                | Currency
                            }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Post-value.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Engagement</div>

                          <div class="value--large">
                            {{ currentProfile.engagement_rate | Percent }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Images-engagement.svg"
                          class="card-icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="profile-panel-content__block flex-column px-4">
                    <div v-if="countHashtags" class="hashtags-container mb-1">
                      <h4 class="label mr-1">
                        Hashtags&nbsp;({{ countHashtags }}):
                      </h4>
                      <text-highlight
                        class="value text__highlight_hashtags"
                        :class="{
                          'text-overflow-ellipsis': !showAllHashtags
                        }"
                        :queries="filter.keyword"
                        >{{
                          formatHashtags(currentProfile.all_hashtags)
                        }}</text-highlight
                      >

                      <a
                        class="value text-nowrap ml-2 flex-middle see-all"
                        href="javascript:;"
                        @click="showAllHashtags = !showAllHashtags"
                      >
                        <template v-if="showAllHashtags">Hide</template>
                        <template v-else>See all</template>
                      </a>
                    </div>
                    <div
                      class="d-flex my-1"
                      v-if="
                        currentProfile.categories &&
                        currentProfile.categories.length
                      "
                    >
                      <h2 class="label mr-1">Category:</h2>
                      <div
                        class="tag"
                        v-for="(category, index) in currentProfile.categories"
                        v-bind:key="category"
                      >
                        <span v-if="index > 0">/</span>
                        <a :title="category">{{ category }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="profile-panel-content__block flex-column px-4">
                    <h2 class="flex-column mt-3 mb-2">Metrics</h2>
                    <div class="profile-content-cards">
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Post per week</div>

                          <div class="value--large">
                            {{ currentProfile.posts_per_week | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Post-per-week.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Replies per post</div>

                          <div class="value--large">
                            {{ currentProfile.replies_per_post | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Replies-per-post.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Images engagement:</div>

                          <div class="value--large">
                            {{ currentProfile.engagement_rate_image | Percent }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Images-engagement.svg"
                          class="card-icon"
                        />
                      </div>
                    </div>
                    <div class="profile-content-cards mt-3">
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Likes per post</div>

                          <div class="value--large">
                            {{ currentProfile.likes_per_post | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Likes-per-post.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Views per video</div>

                          <div class="value--large">
                            {{ currentProfile.views_per_video | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Views-per-video.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Video engagement:</div>

                          <div class="value--large">
                            {{ currentProfile.engagement_rate_video | Percent }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Video-engagement.svg"
                          class="card-icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      profile-panel-content__block
                      profile-content__block_white
                      flex-column
                      mt-4
                      px-4
                    "
                  >
                    <div class="metrics-graph">
                      <div class="graph-section-metrics">
                        <h2>Followers over time</h2>
                        <div class="" v-if="metrics">
                          <line-chart
                            :height="200"
                            ref="cumulativeChart"
                            :chart-data="metricsHistoryChart.chartData"
                            :extra-options="metricsHistoryChart.extraOptions"
                          ></line-chart>
                        </div>
                      </div>

                      <div class="graph-section-metrics">
                        <h2>Average engagement over time</h2>
                        <div class="" v-if="metrics">
                          <line-chart
                            :height="200"
                            ref="cumulativeChart"
                            :chart-data="metricsHistoryChart.chartData2"
                            :extra-options="metricsHistoryChart.extraOptions2"
                          ></line-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="tabProfile === 1" class="">
                  <!-- <div class="profile-panel-content__block flex-column px-4 mt-4">
                  <h2>Emails</h2>
                  <div
                    v-if="Array.isArray(currentProfile.emails)"
                    class="emails"
                  >
                    <div v-for="email in currentProfile.emails" :key="email">
                      {{ email }}
                    </div>
                  </div>
                  <div v-else>
                    {{ currentProfile.emails }}
                  </div>
                </div> -->
                  <div
                    class="profile-panel-content__block flex-column px-4 mt-4"
                  >
                    <profile-audience
                      :social="social"
                      :currentProfile="shownProfile"
                      :shown="tabProfile === 1"
                    ></profile-audience>
                  </div>
                </div>

                <div
                  v-if="tabProfile === 2"
                  class="profile-panel-content__block flex-column mt-4 px-4"
                >
                  <h2>Latest Instagram Posts</h2>
                  <div
                    v-if="highlights"
                    v-masonry
                    transition-duration="0.1s"
                    item-selector=".thumb-highlight"
                  >
                    <div class="row">
                      <a
                        v-masonry-tile
                        class="col-lg-3 col-md-4 col-sm-6 thumb-highlight"
                        v-for="(highlight, index) in highlights"
                        :key="index"
                        target="_blank"
                        :href="highlight.url"
                      >
                        <img
                          :src="
                            'data:image/jpeg;base64,' + highlight.post_pic_url
                          "
                          class="img-responsiv img-thumbnail lazy"
                          :title="currentProfile.full_name"
                          :alt="currentProfile.full_name"
                        />
                        <div class="thumb-media-mark">
                          <img
                            v-if="highlight.is_video"
                            src="/app/img/icons/svg/video.svg"
                          />
                        </div>
                        <div class="thumb-comments">
                          <div class="thumb-likes mr-3 text-center">
                            <i class="fas fa-heart mr-1"></i>
                            <span>{{ highlight.likes | BigNumber }}</span>
                          </div>
                          <div class="thumb-replies text-center">
                            <i class="fas fa-comment mr-1"></i>
                            <span>{{ highlight.replies | BigNumber }}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div v-else class="flex-middle-center py-5">
                    <base-spinner></base-spinner>
                    <span class="ml-2">Loading...</span>
                  </div>
                </div>

                <div
                  v-if="tabProfile === 3"
                  class="profile-panel-content__block mt-4 px-4"
                >
                  <h2 class="">
                    Profiles Similar to {{ currentProfile.full_name }}
                  </h2>

                  <template v-for="(profile, index) in similarProfiles">
                    <div class="col-12 flex-center" :key="index">
                      <profile-card
                        :profile="profile"
                        :social="social"
                        :showEngagementRate="false"
                        :showLikesPerPost="false"
                        :showSelectedToggle="false"
                        @clickProfile="clickRelatedProfile"
                        class="my-3"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </template>

            <!-- tiktok profile tabs -->
            <template v-if="social == socials.tiktok.id">
              <div class="profile-header-tab">
                <div
                  class="profile-header-tab-item"
                  @click="showTab(0)"
                  v-bind:class="{ borderitem: tabProfile === 0 }"
                >
                  Statistic and Metrics
                </div>

                <div
                  class="profile-header-tab-item"
                  @click="showTab(3)"
                  v-bind:class="{ borderitem: tabProfile === 3 }"
                >
                  Influencer Audience
                </div>

                <div
                  class="profile-header-tab-item"
                  @click="showTab(2)"
                  v-bind:class="{ borderitem: tabProfile === 2 }"
                >
                  Posts and Videos
                </div>
              </div>
              <div class="profile-panel-content">
                <div v-if="tabProfile === 0" class="">
                  <div
                    class="
                      profile-panel-content__block
                      profile-panel-content__block--main-values
                      p-4
                    "
                  >
                    <h2 class="flex-column mt-2 mb-2">General information</h2>
                    <div class="profile-content-cards">
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">followers</div>

                          <div class="value--large">
                            {{ currentProfile.fans | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Followers.svg"
                          class="card-icon"
                        />
                      </div>
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Likes</div>

                          <div class="value--large">
                            {{ currentProfile.hearts | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Likes-per-post.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Following</div>

                          <div class="value--large">
                            {{ currentProfile.following | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Following.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Videos:</div>

                          <div class="value--large">
                            {{ currentProfile.videos | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Post-value.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Engagement</div>

                          <div class="value--large">
                            {{ currentProfile.avg_engagement_rate | Percent }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Images-engagement.svg"
                          class="card-icon"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="profile-panel-content__block flex-column px-4">
                    <div
                      v-if="currentProfile.hashtags"
                      class="hashtags-container mb-1"
                    >
                      <h4 class="label mr-1">
                        Hashtags&nbsp;({{ currentProfile.hashtags.length }}):
                      </h4>
                      <text-highlight
                        class="value text__highlight_hashtags"
                        :class="{ 'text-overflow-ellipsis': !showAllHashtags }"
                        :queries="filter.keyword"
                        >{{
                          formatTikTokHashtags(currentProfile.hashtags)
                        }}</text-highlight
                      >

                      <a
                        class="value text-nowrap ml-2 flex-middle see-all"
                        href="javascript:;"
                        @click="showAllHashtags = !showAllHashtags"
                      >
                        <template v-if="showAllHashtags">Hide</template>
                        <template v-else>See all</template>
                      </a>
                    </div>
                    <div class="d-flex my-1" v-if="currentProfile.categories">
                      <h2 class="label mr-1">Category:</h2>
                      <div
                        class="tag"
                        v-for="(category, index) in currentProfile.categories"
                        v-bind:key="category"
                      >
                        <span v-if="index > 0">/</span>
                        <a :title="category">{{ category }}</a>
                      </div>
                    </div>
                  </div>

                  <div class="profile-panel-content__block flex-column px-4">
                    <h2 class="flex-column mt-3 mb-2">Metrics</h2>
                    <div class="profile-content-cards">
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Hearts per fan</div>

                          <div class="value--large">
                            {{ currentProfile.hearts_per_fan | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Likes-per-post.svg"
                          class="card-icon"
                        />
                      </div>
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Hearts per Video</div>

                          <div class="value--large">
                            {{ currentProfile.hearts_per_video | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Views-per-video.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Fans to following</div>

                          <div class="value--large">
                            {{ currentProfile.fans_to_following | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Followers.svg"
                          class="card-icon"
                        />
                      </div>
                    </div>
                    <div class="profile-content-cards mt-3">
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Videos per week</div>

                          <div class="value--large">
                            {{ currentProfile.videos_per_week | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Video-engagement.svg"
                          class="card-icon"
                        />
                      </div>
                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Avg comments</div>

                          <div class="value--large">
                            {{ currentProfile.avg_comments | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Replies-per-post.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Avg Likes</div>

                          <div class="value--large">
                            {{ currentProfile.avg_likes | BigNumber }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Likes-per-post.svg"
                          class="card-icon"
                        />
                      </div>

                      <div class="profile-content-card-top">
                        <div class="main-values">
                          <div class="label">Video engagement:</div>

                          <div class="value--large">
                            {{ currentProfile.avg_engagement_rate | Percent }}
                          </div>
                        </div>
                        <img
                          src="/app/img/icons/svg/Following.svg"
                          class="card-icon"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="
                      profile-panel-content__block
                      profile-content__block_white
                      flex-column
                      mt-4
                      px-4
                    "
                  >
                    <div class="metrics-graph">
                      <div class="graph-section-metrics">
                        <h2>Fans over time</h2>
                        <div class="" v-if="this.metrics">
                          <line-chart
                            :height="200"
                            ref="cumulativeChart"
                            :chart-data="metricsHistoryChartTikTok.chartData"
                            :extra-options="
                              metricsHistoryChartTikTok.extraOptions
                            "
                          ></line-chart>
                        </div>
                      </div>

                      <div class="graph-section-metrics">
                        <h2>Average engagement over time</h2>
                        <div class="" v-if="this.metrics">
                          <line-chart
                            :height="200"
                            ref="cumulativeChart"
                            :chart-data="metricsHistoryChartTikTok.chartData2"
                            :extra-options="
                              metricsHistoryChartTikTok.extraOptions2
                            "
                          ></line-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="tabProfile === 1" class="">
                  <div
                    class="profile-panel-content__block flex-column px-4 mt-4"
                  >
                    <h2>Emails</h2>
                    <div
                      v-if="Array.isArray(currentProfile.emails)"
                      class="emails"
                    >
                      <div v-for="email in currentProfile.emails" :key="email">
                        {{ email }}
                      </div>
                    </div>
                    <div v-else>
                      {{ currentProfile.emails }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="tabProfile === 2"
                  class="profile-panel-content__block flex-column mt-4 px-4"
                >
                  <h2>Latest Tik Tok Posts</h2>
                  <div
                    v-if="highlights"
                    v-masonry
                    transition-duration="0.1s"
                    item-selector=".thumb-highlight"
                  >
                    <div class="row">
                      <a
                        v-masonry-tile
                        class="col-lg-3 col-md-4 col-sm-6 thumb-highlight"
                        v-for="(highlight, index) in highlights"
                        :key="index"
                        target="_blank"
                        :href="highlight.url"
                      >
                        <img
                          :src="
                            'data:image/jpeg;base64,' + highlight.post_pic_url
                          "
                          class="img-responsiv img-thumbnail lazy"
                          :title="currentProfile.full_name"
                          :alt="currentProfile.full_name"
                        />
                        <div class="thumb-media-mark">
                          <img
                            v-if="highlight.is_video"
                            src="/app/img/icons/svg/video.svg"
                          />
                        </div>
                        <div class="thumb-comments">
                          <div class="thumb-likes mr-3 text-center">
                            <i class="fas fa-heart mr-1"></i>
                            <span>{{ highlight.likes | BigNumber }}</span>
                          </div>
                          <div class="thumb-replies text-center">
                            <i class="fas fa-comment mr-1"></i>
                            <span>{{ highlight.replies | BigNumber }}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    v-else-if="loading.highlights"
                    class="flex-middle-center py-5"
                  >
                    <base-spinner></base-spinner>
                    <span class="ml-2">Loading...</span>
                  </div>
                </div>

                <div
                  v-if="tabProfile === 3"
                  class="profile-panel-content__block flex-column mt-4 px-4"
                >
                  <profile-audience
                    :social="social"
                    :currentProfile="shownProfile"
                    :shown="tabProfile === 3"
                  ></profile-audience>
                </div>
              </div>
            </template>

            <!-- youtube profile tabs (for now just one tab, so tab header is omitted) -->
            <template v-if="social == socials.youtube.id">
              <!-- Profile tabs -->
              <!-- <div class="profile-header-tab">
                <div
                  class="profile-header-tab-item"
                  @click="showTab(0)"
                  v-bind:class="{ borderitem: tabProfile === 0 }"
                >
                  Statistic and Metrics
                </div>

                <div
                  class="profile-header-tab-item"
                  @click="showTab(2)"
                  v-bind:class="{ borderitem: tabProfile === 2 }"
                >
                  Posts and Videos
                </div>
              </div> -->
              <tabs
                :pills="false"
                :centered="false"
                tabComponentClasses="black-tabs"
                tabNavWrapperClasses="black-tab-nav-wrapper"
                tabNavClasses="black-tab-nav"
                tabContentClasses="black-tab-content"
                ref="tabs"
                @setActiveTab="setYoutubeActiveTab"
              >
                <tab-pane title="General information">
                  <div class="profile-panel-content">
                    <div class="profile-panel-content__block flex-column px-4">
                      <h2 class="mt-2 mb-2">General information</h2>
                      <div class="metric-row-general">
                        <profile-metric-box
                          title="Subscribers"
                          :value="currentProfile.subscribers | BigNumber"
                          icon="groups"
                          circleColor="#fce4ec"
                          iconColor="#e92165"
                        >
                        </profile-metric-box>
                        <profile-metric-box
                          title="Post likes"
                          :value="currentProfile.avg_likes_pct | Percent"
                          icon="thumb_up"
                          circleColor="#f3e5f5"
                          iconColor="#9c27b0"
                        >
                        </profile-metric-box>

                        <profile-metric-box
                          title="Videos"
                          :value="currentProfile.videos | BigNumber"
                          icon="videocam"
                          circleColor="#ede7f6"
                          iconColor="#673ab7"
                        >
                        </profile-metric-box>

                        <profile-metric-box
                          title="Views"
                          :value="currentProfile.views | BigNumber"
                          icon="visibility"
                          circleColor="#E8EAF6"
                          iconColor="#3F51B5"
                        >
                        </profile-metric-box>

                        <profile-metric-box
                          title="Engagement rate"
                          :value="currentProfile.avg_engagement_rate | Percent"
                          icon="attach_money"
                          circleColor="#eefdcd"
                          iconColor="#B1E539"
                        >
                        </profile-metric-box>
                      </div>
                    </div>
                    <div
                      class="profile-panel-content__block flex-column mt-4 px-4"
                    >
                      <h2 class="mt-2 mb-2">Metrics</h2>
                      <div class="metric-row-metrics">
                        <profile-metric-box
                          title="Views per subscriber"
                          :value="
                            currentProfile.views_per_subscriber | BigNumber
                          "
                          icon="group"
                          circleColor="#E3F2FD"
                          iconColor="#2196F3"
                        >
                        </profile-metric-box>

                        <profile-metric-box
                          title="Views per video"
                          :value="currentProfile.views_per_video | BigNumber"
                          icon="videocam"
                          circleColor="#E3F2FD"
                          iconColor="#2196F3"
                        >
                        </profile-metric-box>

                        <profile-metric-box
                          title="Videos per week"
                          :value="currentProfile.videos_per_week | BigNumber"
                          icon="voice_chat"
                          circleColor="#E3F2FD"
                          iconColor="#2196F3"
                        >
                        </profile-metric-box>
                      </div>
                    </div>
                    <div
                      class="profile-panel-content__block flex-column px-4 mt-4"
                    >
                      <h2 class="mt-2 mb-2">Emails</h2>
                      <div
                        v-if="Array.isArray(currentProfile.emails)"
                        class="emails"
                      >
                        <div
                          v-for="email in currentProfile.emails"
                          :key="email"
                        >
                          {{ email }}
                        </div>
                      </div>
                      <div v-else>
                        {{ currentProfile.emails }}
                      </div>
                    </div>
                    <div
                      v-if="currentProfile.location_country"
                      class="profile-panel-content__block flex-column mt-4 px-4"
                    >
                      <div class="flex-column">
                        <h3>Country</h3>
                        <div class="value">
                          {{ currentProfile.country }}
                        </div>
                      </div>
                    </div>
                    <!--
              <div class="profile-panel-content__block flex-column mt-4 px-4">
                <h3>Latest TikTtok Posts</h3>
              </div>
              -->
                  </div>
                </tab-pane>
                <tab-pane title="Influencer Audience">
                  <profile-audience
                    :social="social"
                    :currentProfile="shownProfile"
                    :shown="youtubeActiveTabIndex === 1"
                  ></profile-audience>
                </tab-pane>
              </tabs>
            </template>
          </div>
        </div>
        <div v-else class="my-4 modal-body flex-middle-center">
          <base-spinner></base-spinner>
          <h3 v-if="loading.profile" class="p-2 mt-2">Loading...</h3>
          <h3 v-else>Woophs, something was wrong!</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getFullName,
  getFullLocation,
  getMainEmail,
  getEngagementRateClass,
  getPermissionErrorMessage
} from "@/utils";
import { ENGAGEMENT_CHART_DATA, MESSAGES, SOCIALS } from "@/constants";
import { mapState, mapGetters } from "vuex";
import * as chartConfigs from "@/components/Charts/config";

import { ProfilePanelRequestData } from "../models/leadProfile";
import { onLogoImageError } from "../utils/index";

import LeadProfileHeader from "./Profiles/LeadProfileHeader.vue";
import ProfileAudience from "./Profiles/ProfileAudience.vue";

import LineChart from "@/components/Charts/LineChart";
import ProfileCard from "@/components/ProfileCard";
import TextHighlight from "vue-text-highlight";

import ProfileMetricBox from "@/components/Profiles/ProfileMetricBox";

export default {
  name: "profile-panel",
  components: {
    LineChart,
    ProfileCard,
    TextHighlight,
    ProfileMetricBox,
    LeadProfileHeader,
    ProfileAudience
  },
  props: {
    show: Boolean,
    //#region Main props for filling panel with data
    social: {
      type: String
    },
    username: {
      type: String
    },
    id: {
      default: null
    },
    //#endregion
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    canAddToCampaign: {
      type: Boolean,
      default: true,
      description: "Whether this panel allows add lead to campaign or not"
    },
    campaignsList: {}
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert,
      filter: (state) => state.influencer.filter[SOCIALS.instagram.id],
      shownProfile: (state) => state.influencer.shownProfile
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess"
    }),
    countHashtags() {
      try {
        const hashtagsObj = JSON.parse(this.currentProfile.all_hashtags);
        return Object.keys(hashtagsObj).length;
      } catch {
        return 0;
      }
    }
  },
  data() {
    return {
      currentProfileRequestData: undefined,
      panelProfileHistory: [],
      getFullName: getFullName,
      getFullLocation: getFullLocation,
      getMainEmail: getMainEmail,
      getEngagementRateClass: getEngagementRateClass,
      getPermissionErrorMessage: getPermissionErrorMessage,
      socials: SOCIALS,
      showAllHashtags: false,
      loading: {
        profile: false,
        highlights: false,
        metrics: false
      },
      engagementRateChartList: ENGAGEMENT_CHART_DATA,
      currentProfile: null,
      similarProfiles: null,
      highlights: null,
      metrics: null,
      emailBoxEvent: null,
      tabProfile: 0,
      youtubeActiveTabIndex: 0,

      metricsHistoryChart: {
        chartData: {
          datasets: [{}],
          labels: []
        },
        chartData2: {
          datasets: [{}],
          labels: []
        },

        extraOptions: chartConfigs.metricsChartOptions,
        extraOptions2: chartConfigs.metricsChartOptions2
      },
      metricsHistoryChartTikTok: {
        chartData: {
          datasets: [{}],
          labels: []
        },
        chartData2: {
          datasets: [{}],
          labels: []
        },

        extraOptions: chartConfigs.metricsChartOptions,
        extraOptions2: chartConfigs.metricsChartOptions2
      }
    };
  },
  methods: {
    onLogoImageError: onLogoImageError,
    closePanel() {
      /* prevent right menu for emailbox */
      if (this.emailBoxEvent) {
        this.$refs.emailBox.removeEventListener(
          "contextmenu",
          this.preventMailBoxContextMenu
        );
        this.emailBoxEvent = undefined;
      }

      /* slide out panel */
      document.querySelector(
        ".profile-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);

      window.stop();
    },

    formatHashtags(str) {
      const hashtagsObj = JSON.parse(str);
      return Object.keys(hashtagsObj)
        .map((tag) => `#${tag}`)
        .sort()
        .join(", ");
    },
    formatTikTokHashtags(arr) {
      let newArr = "";
      arr.forEach((k) => (newArr = newArr + "#" + k));

      return newArr;
    },
    showTab(tabId) {
      this.tabProfile = tabId;
    },
    getProfileLink() {
      let link = "";
      let instagramBaselink = "https://www.instagram.com/";
      let youtubeBaselink = "https://www.youtube.com/channel/";
      let tikTokBaselink = "https://www.tiktok.com/";

      switch (this.currentProfileRequestData.social) {
        case "instagram":
          link = instagramBaselink + this.currentProfile.username;
          break;
        case "youtube":
          link = youtubeBaselink + this.currentProfile.id;
          break;
        case "tiktok":
          link = tikTokBaselink + "@" + this.currentProfile.username;
          break;
        default:
          link = "";
          break;
      }

      return link;
    },

    async getSingleProfile(activeTabIndex) {
      if (activeTabIndex == null) activeTabIndex = 0;

      this.loading.profile = true;
      try {
        const data = await this.$store.dispatch(
          "influencer/getSingleProfile",
          this.currentProfileRequestData
        );

        if (data) {
          this.loading.profile = false;
          switch (this.currentProfileRequestData.social) {
            case SOCIALS.instagram.id:
              this.currentProfile = data.profile;
              this.similarProfiles = data.similar.hits;
              this.getSingleProfileHighlights();
              this.tabProfile = activeTabIndex;
              break;
            case SOCIALS.tiktok.id:
              this.currentProfile = data.profile;
              this.getSingleProfileHighlights();
              this.tabProfile = activeTabIndex;
              break;
            case SOCIALS.youtube.id:
              this.currentProfile = data;
              break;
            default:
          }

          this.$store.dispatch("influencer/showProfile", this.currentProfile);
        }
      } catch {
        this.loading.profile = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["influencer__get_single_profile-failed"],
            alertClass: "danger"
          });
        }
      }
    },

    async getSingleProfileHighlights() {
      this.loading.highlights = true;
      try {
        const { data } = await this.$store.dispatch(
          "influencer/getSingleProfileHighlights",
          {
            social: this.currentProfileRequestData.social,
            username: this.currentProfileRequestData.username
          }
        );
        this.loading.highlights = false;
        if (data) {
          this.highlights = data.highlights;
        }
      } catch {
        this.loading.highlights = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content:
              MESSAGES["influencer__get_single_profile_highlights-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getMetrics() {
      this.loading.highlights = true;

      try {
        const data = await this.$store.dispatch("influencer/getMetrics", {
          social: this.currentProfileRequestData.social,
          username: this.currentProfileRequestData.username
        });

        if (data) {
          this.metrics = data.reverse();

          if (this.currentProfileRequestData.social === "instagram") {
            this.buildMetricsChart(this.metrics);
          }

          if (this.currentProfileRequestData.social === "tiktok") {
            this.buildMetricsChartTikTok(this.metrics);
          }

          this.loading.metrics = false;
        }
      } catch {
        this.loading.metrics = false;
        // if (!this.showAlert) {
        //   this.$store.dispatch("setAlert", {
        //     showAlert: true,
        //     content:
        //       MESSAGES["influencer__get_single_profile_highlights-failed"],
        //     alertClass: "danger",
        //   });
        // }
      }
    },

    buildMetricsChart(rawData) {
      const label = [];
      const engagement_rate = [];
      const followers = [];

      rawData.forEach(function (el) {
        followers.push(el.followers);
        label.push(el.date.slice(5, 7) + "." + el.date.slice(2, 4));
        engagement_rate.push(el.engagement_rate);
      });

      const chartData = {
        datasets: [
          {
            backgroundColor: "#E0F2F1",
            borderColor: "#8BC34A",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 2,
            lineTension: 0.2,
            label: "followers",
            data: followers,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1
          }
        ],
        labels: label
      };

      const chartData2 = {
        datasets: [
          {
            backgroundColor: "#F1F8E9",
            borderColor: "#CDDC39",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 2,
            lineTension: 0.2,
            label: "Engagement Rate",
            data: engagement_rate,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1
          }
        ],
        labels: label
      };

      this.metricsHistoryChart.chartData = chartData;
      this.metricsHistoryChart.chartData2 = chartData2;
    },
    buildMetricsChartTikTok(rawData) {
      const label = [];
      const engagement_rate = [];
      const followers = [];

      rawData.forEach(function (el) {
        followers.push(el.fans);
        label.push(el.date.slice(5, 7) + "." + el.date.slice(2, 4));
        engagement_rate.push(el.avg_engagement_rate);
      });

      const chartData = {
        datasets: [
          {
            backgroundColor: "#E0F2F1",
            borderColor: "#8BC34A",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 2,
            lineTension: 0.2,
            label: "followers",
            data: followers,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1
          }
        ],
        labels: label
      };

      const chartData2 = {
        datasets: [
          {
            backgroundColor: "#F1F8E9",
            borderColor: "#CDDC39",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 2,
            lineTension: 0.2,
            label: "Engagement Rate",
            data: engagement_rate,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1
          }
        ],
        labels: label
      };

      this.metricsHistoryChartTikTok.chartData = chartData;
      this.metricsHistoryChartTikTok.chartData2 = chartData2;
    },

    displayEditCampaignPanel() {
      this.$emit("displayEditCampaignPanel");
    },
    addLeadsToExistingCampaign(campaign) {
      this.$emit("addLeadsToExistingCampaign", campaign);
    },
    preventMailBoxContextMenu(e) {
      e.preventDefault();
    },
    clickRelatedProfile(profile) {
      this.panelProfileHistory.push(this.currentProfileRequestData);
      let newProfileRequestData = new ProfilePanelRequestData(
        this.currentProfileRequestData.social,
        profile.username,
        profile.id
      );
      this.currentProfileRequestData = newProfileRequestData;

      // Quick fix for displaying progress
      this.currentProfile = null;

      this.getSingleProfile(0);
      this.getMetrics();
    },
    backToPrevious() {
      let previousProfileRequestData = this.panelProfileHistory.pop();
      this.currentProfileRequestData = previousProfileRequestData;

      // Quick fix for displaying progress
      this.currentProfile = null;

      this.getSingleProfile(3);
      this.getMetrics();
    },
    setYoutubeActiveTab(tabIndex) {
      this.youtubeActiveTabIndex = tabIndex;
    }
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".profile-panel .modal-dialog"
          ).style.right = `0px`;
        }, 0);

        this.currentProfileRequestData = new ProfilePanelRequestData(
          this.social,
          this.username,
          this.id
        );

        this.getSingleProfile();
        this.getMetrics();
      } else {
        /* reset panel */
        this.currentProfile = null;
        this.similarProfiles = null;
        this.highlights = null;

        this.currentProfileRequestData = null;

        documentClasses.remove("modal-open");
        document.querySelector(".profile-panel").scrollTop = 0;

        this.$store.dispatch("influencer/hideProfile");
      }
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/custom/profile-panel";
@import "../assets/scss/custom/profile-card";

.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}

.text__highlight {
  font-weight: bold;
  background: #ffff04 !important;
  padding: 0;
}
</style>
