export const createLeadProfile = (social, profileData) => {
    if (social === "instagram") {
        return new InstagramLeadProfile(profileData.id)
    } else if (social === "youtube") {
        return new InstagramLeadProfile(profileData.id) 
    } else if (social === "tiktok") {
        return new InstagramLeadProfile(profileData.id)
    } else {
        throw "Unknow profile type";
    }
};

export class LeadProfile {
    constructor(id) {
        this.id = id;
        this.social = "unknown";
    }
}

export class InstagramLeadProfile extends LeadProfile {
    constructor(...args) {
        super(...args);

        this.social = "instagram";

        this.profile_pic_url = "";
        this.first_name = "";
        this.username = "";
        this.biography = "";
        this.location_country = "";
        this.website = "";
        this.is_verified = "";

        this.business_email = "";
        this.emails = [];

    }

    getProfileLink() {
        let instagramBaselink = "https://www.instagram.com/";
        let link = instagramBaselink + this.username;
        return link;
    }
}

export class YoutubeLeadProfile extends LeadProfile {
    constructor(...args) {
        super(...args);

        this.social = "youtube";

        this.avatar_url = "";
        this.id = "";
        this.title = "";
        this.is_verified = "";
        this.description = "";
        this.country = "";
        this.website = "";

        this.business_email = "";
        this.emails = [];

    }

    getProfileLink() {
        let youtubeBaselink = "https://www.youtube.com/channel/";
        let link = youtubeBaselink + this.currentProfile.id;
        return link;
    }
}

export class TikTokLeadProfile extends LeadProfile {
    constructor(...args) {
        super(...args);

        this.social = "tiktok";

        this.nickname = "";
        this.covers_small = [];
        this.is_verified = "";
        this.username = "";
        this.signature = "";
    }

    getProfileLink() {
        let tikTokBaselink = "https://www.tiktok.com/";
        let link = tikTokBaselink + "@" + this.currentProfile.username;
        return link;
    }
}

export class ProfilePanelRequestData {
    constructor(social, username, id) {
      this.social = social;
      this.username = username;
      this.id = id;
    }
  }