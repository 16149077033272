<template>
  <div class="metric-line-percentage">
    <div class="metric-line-title">{{ title }}</div>
    <div class="metric-line-value">
      <div class="value-row">{{ value }}%</div>
      <div class="line-row" :style="{ 'border-color': lineColor }">
        <div
          class="line-bar"
          :style="{ width: value + '%', 'background-color': lineColor }"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "metric-line-percentage",
  props: {
    title: {
      type: String
    },
    value: {
      type: [Number, String]
    },
    lineColor: {
      type: String,
      default: "#5e72e4"
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/custom/functions";
@import "../../assets/scss/custom/variables";

.metric-line-percentage {
  display: flex;
  align-items: end;
  width: 100%;

  .metric-line-title {
    flex: 1 1 60%;
    line-height: 1;
  }

  .metric-line-value {
    flex: 1 1 40%;

    display: flex;
    flex-direction: column;

    .value-row {
      text-align: right;
    }

    .line-row {
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%) inset;

      .line-bar {
        height: 10px;
      }
    }
  }
}
</style>