<template>
  <div :class="'profile-card-container ' + (checkbox ? 'profile-checked' : '')">
    <div v-if="showSelectedToggle" class="profile-checkbox-container">
      <base-checkbox
        v-model="checkbox"
        class="custom-control-alternative"
        @change="checkBoxToggle"
      ></base-checkbox>
    </div>
    <div
      class="profile-card profile-card-content card flex-column p-3"
      @click="clickProfile"
    >
      <!-- instagram -->
      <template v-if="social == socials.instagram.id">
        <!-- desktop layout -->
        <div class="profile-content justify-content-between align-items-center">
          <div class="profile-content-left d-flex ml-3">
            <div class="avatar">
              <img
                :src="profile.profile_pic_url"
                itemprop="image"
                :alt="profile.first_name"
                :title="profile.first_name"
                @error="onLogoImageError($event)"
              />
            </div>

            <div class="flex-column ml-2 w-100">
              <div class="flex-middle flex-wrap">
                <div class="profile-name mr-2">{{ getFullName(profile) }}</div>
                <div
                  v-if="profile.is_verified"
                  class="profile-verified mr-2"
                ></div>
                <div v-else class="profile-unverified mr-2"></div>
                <div class="profile-username">@{{ profile.username }}</div>
              </div>

              <div class="profile-content__bio">
                {{ profile.biography }}
              </div>

              <div class="d-flex flex-wrap">
                <a
                  v-if="profile.website"
                  :href="profile.website"
                  target="_blank"
                  class="profile-site mr-3"
                >
                  <i class="fas fa-globe"></i>
                  <span>{{ profile.website }}</span>
                </a>
                <a v-if="getMainEmail(profile)" class="profile-email">
                  <i class="fas fa-envelope"></i>
                  <span>{{ getMainEmail(profile) }}</span>
                </a>
              </div>
              <div
                v-if="profile.all_hashtags && profile.all_hashtags.length"
                class="font-weight-bold text-overflow-ellipsis w-75"
              >
                <b>{{ formatHashtags(profile.all_hashtags) }}</b>
              </div>
            </div>
          </div>

          <div class="profile-content-right">
            <div v-if="showFollowerCount" class="profile-followers mb-2">
              <div class="label">Followers</div>
              <div class="value">{{ profile.followers | BigNumber }}</div>
            </div>

            <div v-if="showEngagementRate" class="mb-1">
              <div class="profile-followers">
                <div class="label mr-1">Engagement Rate:</div>
                <div class="value value-eng">
                  {{ profile.engagement_rate | Percent }}
                </div>
              </div>
              <div class="w-100 px-1 mt-2">
                <base-progress
                  type="primary"
                  :show-percentage="false"
                  :class="'pt-0 pt-' + getEngagementRateClass(profile)"
                  :value="
                    engagementRateChartList[getEngagementRateClass(profile)]
                      .percent
                  "
                />
              </div>
            </div>

            <div v-if="showLikesPerPost" class="profile-followers">
              <div class="label">Likes per Post:</div>
              <div class="value">{{ profile.posts | BigNumber }}</div>
            </div>
            
            <div v-if="profile.tiktok_username || profile.youtube_channel" class="profile-followers">
              <div class="label mb-2 mt-1">Social Links</div>
              <div class="d-flex justify-content-around">
                <div v-if="profile.tiktok_username" class="flex-column">
                  <img src="/app/img/icons/svg/tiktok_icon_sm.svg" alt="TickTok logo" class="value mr-3 ml-3" />
                  <small class="mr-3 ml-3">{{ profile.fans | BigNumber }}</small>
                </div>
                <div v-if="profile.youtube_channel" class="flex-column">
                  <img src="/app/img/icons/svg/youtube_icon_sm.svg" alt="Youtube logo" class="value pt-1" />
                  <small class="pt-1">{{ profile.subscribers | BigNumber }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- mobile layout -->
        <div class="profile-content--mobile justify-content-between mb-3">
          <div class="profile-name-container flex-middle">
            <div class="avatar">
              <div class="chart">
                <svg
                  :width="engagementRateChartRadius * 2"
                  :height="engagementRateChartRadius * 2"
                >
                  <circle
                    :r="engagementRateChartRadius / 2"
                    :cx="engagementRateChartRadius"
                    :cy="engagementRateChartRadius"
                    :style="{
                      strokeDasharray: getChartDashArray(profile),
                      strokeWidth: engagementRateChartRadius,
                    }"
                    class="pie"
                  ></circle>
                </svg>
              </div>
              <!-- <img
                :src="profile.profile_pic_url"
                itemprop="image"
                :alt="profile.first_name"
                :title="profile.first_name"
                @error="onLogoImageError($event)"
              > -->
              <avatar
                :username="profile.full_name || profile.username"
                :size="32"
                style="z-index: 1"
              />
            </div>
            <div class="flex-column ml-2">
              <div class="flex-middle mr-1">
                <div class="profile-name">{{ profile.full_name }}</div>
                <a
                  v-if="profile.website"
                  :href="profile.website"
                  class="profile-site m-1"
                  target="_blank"
                >
                  <i class="fas fa-globe"></i>
                </a>
                <a v-if="getMainEmail(profile)" class="profile-email m-1">
                  <i class="fas fa-envelope"></i>
                </a>
              </div>
              <div class="profile-username">@{{ profile.username }}</div>
            </div>
          </div>
        </div>

        <div class="profile-content--mobile profile-content--mobile__bio mb-3">
          {{ profile.biography }}
        </div>

        <div class="profile-content--mobile">
          <div v-if="showFollowerCount" class="d-flex mr-3">
            <div class="value mr-1">{{ profile.followers | BigNumber }}</div>
            <div class="label">Followers</div>
          </div>
          <div v-if="showLikesPerPost" class="d-flex">
            <div class="value mr-1">{{ profile.posts | BigNumber }}</div>
            <div class="label">Likes per Post</div>
          </div>
        </div>

        <div v-if="profile.tiktok_username || profile.youtube_channel" class="profile-content--mobile mt-2">
          <div class="d-flex flex-column">
            <div>Social Links</div>
            <div class="d-flex ml-1 mt-1">
              <div v-if="profile.tiktok_username" class="d-flex flex-column mr-3">
                <img src="/app/img/icons/svg/tiktok_icon_sm.svg" width="22.5px" alt="Tiktok logo" />
                <small style="margin-left: -2px">{{ profile.fans | BigNumber }}</small>
              </div>
              <div v-if="profile.youtube_channel" class="d-flex flex-column">
                <img src="/app/img/icons/svg/youtube_icon_sm.svg" alt="Youtube logo" />
                <small>{{ profile.subscribers | BigNumber }}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="profile-content--mobile">
          <div
            v-if="profile.all_hashtags && profile.all_hashtags.length"
            class="font-weight-bold text-overflow-ellipsis mt-3"
          >
            <b>{{ formatHashtags(profile.all_hashtags) }}</b>
          </div>
        </div>
      </template>

      <!-- need to research the tiktok & youtube design -->
      <!-- tiktok -->
      <template v-else-if="social == socials.tiktok.id">
        <!-- desktop layout -->
        <div class="profile-content justify-content-between align-items-center">
          <div class="profile-content-left d-flex ml-3">
            <div class="avatar">
              <img
                :src="profile.covers_small[0]"
                itemprop="image"
                :alt="profile.nickname"
                :title="profile.nickname"
                @error="onLogoImageError($event)"
              />
            </div>

            <div class="flex-column ml-2">
              <div class="flex-middle flex-wrap">
                <div class="profile-name mr-2">{{ profile.nickname }}</div>
                <div
                  v-if="profile.is_verified"
                  class="profile-verified mr-2"
                ></div>
                <div v-else class="profile-unverified mr-2"></div>
                <div class="profile-username">@{{ profile.username }}</div>
              </div>
              <div class="flex-middle flex-wrap">
                <div class="profile-username">{{ profile.signature }}</div>
              </div>

              <div class="d-flex flex-wrap">
                <a
                  v-if="profile.website"
                  :href="profile.website"
                  target="_blank"
                  class="profile-site mr-3"
                >
                  <i class="fas fa-globe"></i>
                  <span>{{ profile.website }}</span>
                </a>
                <a v-if="getMainEmail(profile)" class="profile-email">
                  <i class="fas fa-envelope"></i>
                  <span>{{ getMainEmail(profile) }}</span>
                </a>
              </div>
            </div>
          </div>

          <div class="profile-content-right">
            <div v-if="showFollowerCount" class="profile-followers mb-2">
              <div class="label">Fans</div>
              <div class="value">{{ profile.fans | BigNumber }}</div>
            </div>

            <div v-if="showEngagementRate" class="mb-1">
              <div class="profile-followers">
                <div class="label">Engagement Rate:</div>
                <div class="value">
                  {{ profile.avg_engagement_rate | Percent }}
                </div>
              </div>
            </div>

            <div v-if="showLikesPerPost" class="profile-followers">
              <div class="label">Hearts:</div>
              <div class="value">{{ profile.hearts | BigNumber }}</div>
            </div>

            <div v-if="profile.instagram_username || profile.youtube_channel" class="profile-followers">
              <div class="label mt-2">Social Links</div>
              <div class="d-flex justify-content-around">
                <div v-if="profile.instagram_username" class="flex-column mr-3">
                  <img src="/app/img/icons/svg/instagram_icon_sm.svg" alt="Instagram logo" class="value" />
                  <small>{{ profile.followers | BigNumber }}</small>
                </div>
                <div v-if="profile.youtube_channel" class="flex-column">
                  <img src="/app/img/icons/svg/youtube_icon_sm.svg" alt="Youtube logo" class="value pt-1" />
                  <small class="pt-1">{{ profile.subscribers | BigNumber }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- mobile layout -->
        <div class="profile-content--mobile justify-content-between mb-3">
          <div class="profile-name-container flex-middle">
            <div class="avatar">
              <img
                :src="profile.covers_small[0]"
                itemprop="image"
                :alt="profile.nickname"
                :title="profile.nickname"
                @error="onLogoImageError($event)"
              />
            </div>
            <div class="flex-column ml-2">
              <div class="flex-middle mr-1">
                <div class="profile-name">{{ profile.nickname }}</div>
                <a
                  v-if="profile.website"
                  :href="profile.website"
                  class="profile-site m-1"
                  target="_blank"
                >
                  <i class="fas fa-globe"></i>
                </a>
                <a v-if="getMainEmail(profile)" class="profile-email m-1">
                  <i class="fas fa-envelope"></i>
                </a>
              </div>
              <div class="profile-username">@{{ profile.username }}</div>
            </div>
          </div>
        </div>

        <div class="profile-content--mobile">
          <div v-if="showFollowerCount" class="d-flex mr-3">
            <div class="value mr-1">{{ profile.fans | BigNumber }}</div>
            <div class="label">Fans</div>
          </div>
          <div v-if="showLikesPerPost" class="d-flex">
            <div class="value mr-1">{{ profile.hearts | BigNumber }}</div>
            <div class="label">Hearts</div>
          </div>
        </div>

        <div v-if="profile.instagram_username || profile.youtube_channel" class="profile-content--mobile mt-2">
          <div class="d-flex flex-column">
            <div>Social Links</div>
            <div class="d-flex ml-1 mt-1">
              <div v-if="profile.instagram_username" class="d-flex flex-column mr-3">
                <img src="/app/img/icons/svg/instagram_icon_sm.svg" width="22.5px" alt="Instagram logo" />
                <small style="margin-left: -2px">{{ profile.followers | BigNumber }}</small>
              </div>
              <div v-if="profile.youtube_channel" class="d-flex flex-column">
                <img src="/app/img/icons/svg/youtube_icon_sm.svg" alt="Youtube logo" />
                <small>{{ profile.subscribers | BigNumber }}</small>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- youtube -->
      <template v-else-if="social == socials.youtube.id">
        <!-- desktop layout -->
        <div class="profile-content justify-content-between align-items-center">
          <div class="profile-content-left d-flex ml-3">
            <div class="avatar">
              <img
                :src="profile.avatar_url"
                itemprop="image"
                :alt="profile.title"
                :title="profile.title"
                @error="onLogoImageError($event)"
              />
            </div>

            <div class="flex-column ml-2">
              <div class="flex-middle flex-wrap">
                <div class="profile-name mr-2">{{ profile.title }}</div>
                <div
                  v-if="profile.is_verified"
                  class="profile-verified mr-2"
                ></div>
                <div v-else class="profile-unverified mr-2"></div>
                <div class="profile-username">@{{ profile.id }}</div>
              </div>

              <div class="flex-middle flex-wrap">
                <div class="profile-username" v-if="profile.description">
                  {{ profile.description | ShortIntro }}
                </div>
              </div>

              <div class="d-flex flex-wrap">
                <a
                  v-if="profile.website"
                  :href="profile.website"
                  target="_blank"
                  class="profile-site mr-3"
                >
                  <i class="fas fa-globe"></i>
                  <span>{{ profile.website }}</span>
                </a>
                <a v-if="getMainEmail(profile)" class="profile-email">
                  <i class="fas fa-envelope"></i>
                  <span>{{ getMainEmail(profile) }}</span>
                </a>
              </div>
            </div>
          </div>

          <div class="profile-content-right">
            <div v-if="showFollowerCount" class="profile-followers mb-2">
              <div class="label">Subscribers</div>
              <div class="value">{{ profile.subscribers | BigNumber }}</div>
            </div>

            <div v-if="showEngagementRate" class="mb-1">
              <div class="profile-followers">
                <div class="label mr-1">Engagement rate:</div>
                <div class="value">
                  {{ profile.avg_engagement_rate | Percent }}
                </div>
              </div>
            </div>

            <div v-if="showLikesPerPost" class="profile-followers">
              <div class="label mr-1">Post likes:</div>
              <div class="value">{{ profile.avg_likes | BigNumber }}</div>
            </div>

            <div v-if="profile.instagram_username || profile.tiktok_username" class="profile-followers">
              <div class="label mt-1 mb-1">Social Links</div>
              <div class="d-flex justify-content-around">
                <div v-if="profile.instagram_username" class="flex-column">
                  <img src="/app/img/icons/svg/instagram_icon_sm.svg" alt="Instagram logo" class="value" />
                  <small>{{ profile.followers | BigNumber }}</small>
                </div>
                <div v-if="profile.tiktok_username" class="flex-column ">
                  <img src="/app/img/icons/svg/tiktok_icon_sm.svg" alt="TickTok logo" class="value ml-3" />
                  <small class="ml-3">{{ profile.fans | BigNumber }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- mobile layout -->
        <div class="profile-content--mobile justify-content-between mb-3">
          <div class="profile-name-container flex-middle">
            <div class="avatar">
              <img
                :src="profile.avatar_url"
                itemprop="image"
                :alt="profile.title"
                :title="profile.title"
                @error="onLogoImageError($event)"
              />
            </div>
            <div class="flex-column ml-2">
              <div class="flex-middle mr-1">
                <div class="profile-name">{{ profile.title }}</div>
                <a
                  v-if="profile.website"
                  :href="profile.website"
                  class="profile-site m-1"
                  target="_blank"
                >
                  <i class="fas fa-globe"></i>
                </a>
                <a v-if="getMainEmail(profile)" class="profile-email m-1">
                  <i class="fas fa-envelope"></i>
                </a>
              </div>
              <div class="profile-username">@{{ profile.id }}</div>
            </div>
          </div>
        </div>

        <div class="profile-content--mobile">
          <div v-if="showFollowerCount" class="d-flex mr-3">
            <div class="value mr-1">{{ profile.subscribers | BigNumber }}</div>
            <div class="label">Subscribers</div>
          </div>
          <div v-if="showEngagementRate" class="d-flex">
            <div class="value mr-1">
              {{ profile.avg_engagement_rate | Percent }}
            </div>
            <div class="label">Engagement</div>
          </div>
        </div>
        <div v-if="profile.instagram_username || profile.tiktok_username" class="profile-content--mobile mt-2">
          <div class="d-flex flex-column">
            <div>Social Links</div>
            <div class="d-flex ml-1 mt-1">
              <div v-if="profile.instagram_username" class="d-flex flex-column mr-3">
                <img src="/app/img/icons/svg/instagram_icon_sm.svg" width="22.5px" alt="Instagram logo" />
                <small style="margin-left: -2px">{{ profile.followers | BigNumber }}</small>
              </div>
              <div v-if="profile.tiktok_username" class="d-flex flex-column">
                <img src="/app/img/icons/svg/tiktok_icon_sm.svg" width="22.5px" alt="Tiktok logo" />
                <small style="margin-left: -3px">{{ profile.fans | BigNumber }}</small>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  ENGAGEMENT_CHART_DATA,
  ENGAGEMENT_CLASSES,
  SOCIALS,
} from "@/constants";
import { getEngagementRateClass, getFullName, getMainEmail, onLogoImageError } from "@/utils";
const PIE_RADIUS = 20;
import Avatar from "vue-avatar";

export default {
  components: {
    Avatar,
  },
  name: "profile-card",
  props: {
    profile: {
      type: Object,
      description: "Card profile object",
    },
    social: {
      type: String,
      description: "Social - instagram, tiktok, youtube",
    },
    selected: {
      type: Boolean,
      description: "is Selected",
    },
    showFollowerCount: {
      type: Boolean,
      default: true,
      description: "toggle display of num of followers",
    },
    showEngagementRate: {
      type: Boolean,
      default: true,
      description: "toggle display of engagement rate",
    },
    showLikesPerPost: {
      type: Boolean,
      default: true,
      description: "toggle display of likes per post",
    },
    showSelectedToggle: {
      type: Boolean,
      default: true,
      description: "toggle display of selected checkbox"
    },
  },
  data() {
    return {
      getEngagementRateClass: getEngagementRateClass,
      getFullName: getFullName,
      getMainEmail: getMainEmail,
      socials: SOCIALS,
      checkbox: this.selected,
      engagementRateChartList: ENGAGEMENT_CHART_DATA,
      engagementRateChartRadius: PIE_RADIUS,
    };
  },
  methods: {
    onLogoImageError: onLogoImageError,
    getChartDashArray(profile) {
      const total = Math.PI * PIE_RADIUS;
      const className = getEngagementRateClass(profile);
      return `${(ENGAGEMENT_CHART_DATA[className].percent / 100.0) * total} ${
        100 - ENGAGEMENT_CHART_DATA[className].percent
      }`;
    },
    getChartColor(profile) {
      return ENGAGEMENT_CLASSES[getEngagementRateClass(profile)].color;
    },
    checkBoxToggle() {
      this.$emit("checkBoxToggle", this.checkbox);
    },
    clickProfile() {
      this.$emit("clickProfile", this.profile);
    },
    formatHashtags(hashtags) {
      if (typeof hashtags === "string") {
        const hashtagsObj = JSON.parse(hashtags);
        return Object.keys(hashtagsObj)
          .map((tag) => `#${tag}`)
          .sort()
          .join(", ");
      } else if (hashtags && hashtags.length) {
        return hashtags
          .map((tag) => `#${tag}`)
          .sort()
          .join(", ");
      }
      return "";
    },
  },
};
</script>
<style></style>
