<template>
  <div>
    <div v-if="loading" class="flex-middle-center py-5">
      <base-spinner></base-spinner>
      <span class="ml-2">Loading...</span>
    </div>
    <div v-else>
      <div v-if="audienceData">
        <div class="metrics-row">
          <div class="metric-block">
            <div class="block-title">Gender split</div>
            <div class="block-data">
              <doughnut-chart
                :height="220"
                ref="genderChart"
                :chart-data="genderSplitChartData"
                :extra-options="genderSplitExtraOptions"
              ></doughnut-chart>
            </div>
          </div>
          <div class="metric-block" v-if="cities.length > 0">
            <div class="block-title">Location by city</div>
            <div class="block-data">
              <div class="locations-list">
                <metric-line-percentage
                  v-for="location in cities"
                  :key="location.name"
                  :title="location.name"
                  :value="location.weight"
                >
                </metric-line-percentage>
              </div>
            </div>
          </div>
          <div class="metric-block">
            <div class="block-title">Audience country</div>
            <div class="block-data">
              <div class="locations-list">
                <metric-line-percentage
                  v-for="location in countries"
                  :key="location.name"
                  :title="location.name"
                  :value="location.weight"
                >
                </metric-line-percentage>
              </div>
            </div>
          </div>

          <div class="metric-block" v-if="fakesValue">
            <div class="block-title">Fake followers</div>
            <div class="block-data">
              <div class="fake-followers-wrap">
                <div class="fake-followers-icon">
                  <span class="material-icons"> no_accounts </span>
                </div>
                <div class="fake-followers-value">{{ fakesValue }} %</div>
              </div>
            </div>
          </div>
          <div class="metric-block">
            <div class="block-title">Audience ages</div>
            <div class="block-data">
              <div class="locations-list">
                <metric-line-percentage
                  v-for="age in ages"
                  :key="age.code"
                  :title="age.code"
                  :value="age.weight"
                >
                </metric-line-percentage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex-middle-center py-5">
        We don't have audience data for this profile
      </div>
    </div>
  </div>
</template>

<script>
import * as chartConfigs from "@/components/Charts/config";
import DoughnutChart from "@/components/Charts/DoughnutChart";

import MetricLinePercentage from "./MetricLinePercentage.vue";

import LeadProfile from "../../models/leadProfile";
import { ProfilePanelRequestData } from "../../models/leadProfile";

export default {
  name: "profile-audience",
  components: {
    DoughnutChart,
    MetricLinePercentage
  },
  props: {
    social: {
      type: String
    },
    currentProfile: {
      type: LeadProfile
    },
    shown: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    audienceData() {
      // sh.sergey - workaround. Needs to check loaind in order include watching for this after loading complete
      if (!this.loading)
        return this.currentProfile && this.currentProfile.audienceData;
      else  
        return null;
    },
    cities() {
      if (!this.audienceData) return [];

      this.audienceData.location_by_city.forEach((location) => {
        location.weight = (location.weight * 100).toFixed(2);
      });

      return this.audienceData.location_by_city;
    },
    countries() {
      if (!this.audienceData) return [];

      this.audienceData.audience_country.forEach((location) => {
        location.weight = (location.weight * 100).toFixed(2);
      });

      return this.audienceData.audience_country;
    },
    ages() {
      if (!this.audienceData) return [];

      this.audienceData.audience_ages.forEach((location) => {
        location.weight = (location.weight * 100).toFixed(2);
      });

      return this.audienceData.audience_ages;
    },
    fakesValue() {
      if (!this.audienceData) return "n/a";

      if (!this.audienceData.fake_followers_ratio)
        return null;

      return (this.audienceData.fake_followers_ratio * 100).toFixed(2);
    },
    genderSplitChartData() {
      if (!this.audienceData) return {};

      var genderSplit = this.audienceData.gender_split;

      var labels = [];
      var data = [];
      genderSplit.forEach((element) => {
        labels.push(element.code);
        data.push((element.weight * 100).toFixed(2));
      });

      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: ["#fb6340", "#2f80ed"],
            data: data
          }
        ]
      };
    },
    genderSplitExtraOptions() {
      return chartConfigs.doughnutChartOption;
    }
  },
  mounted() {},
  watch: {
    shown: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal) {
          this.loading = true;

          let profileRequestData = new ProfilePanelRequestData(
            this.social,
            this.currentProfile.username,
            this.currentProfile.id
          );

          await this.$store.dispatch(
            "influencer/getAudience",
            profileRequestData
          );
          this.loading = false;
        }
      }
    }
  }
};
</script>


<style lang="scss" >
@import "../../assets/scss/custom/functions";
@import "../../assets/scss/custom/variables";
@import "../../assets/scss/custom/mixins";
.metrics-row {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;

  .metric-block {
    margin: 10px;

    .block-title {
      font-weight: bold;

      margin-bottom: 10px;
    }

    .block-data {
      padding: 10px;
      height: 240px;
      min-width: 240px;
      border-radius: 10px;
      background-color: $gray-header;
    }

    .locations-list {
      height: 100%;
      overflow-y: auto;
    }

    .fake-followers-wrap {
      text-align: center;
      padding-top: 10px;
      color: $blue;

      .fake-followers-icon {
        .material-icons {
          font-size: 150px;
        }
      }

      .fake-followers-value {
        text-align: center;
        font-size: 26px;
      }
    }
  }
}
</style>